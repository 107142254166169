<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading">
          <v-card-text>
            <div class="d-flex align-center mb-2">
              <v-chip small label color="btnColor" class="white--text" v-if="metadata.stage === 0">Open</v-chip>
              <v-chip small label color="error" class="" v-else-if="metadata.stage === 1">Freeze</v-chip>
              <v-chip small label color="success" class="" v-else-if="metadata.stage === 2">Close</v-chip>
              <v-btn icon small class="ml-2" @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            </div>
            <div class="d-flex align-center mb-4" v-if="metadata._id">
              <div>
                <div class="d-flex align-center">
                  <div class="subtitle-1 font-weight-bold mr-2">
                    {{ metadata.description }}
                  </div>
                </div>
                <div>
                  <span class="mr-2">
                    <v-icon class="mr-1">mdi-calendar-range</v-icon> Period:
                  </span>
                  <span class="mr-1">{{ $nova.formatDate(metadata.from) }}</span>
                  <span class="mr-1">-{{ $nova.formatDate(metadata.to) }}</span>
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
            <div class="mb-2 d-flex align-center" v-if="metadata._id && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete'))">
              <v-btn v-if="setting.project" @click="updateConfig()" small class="white--text" color="btnColor">Save Changes</v-btn>
              <v-btn v-else @click="addConfig()" small class="white--text" color="bntColor">Save Changes</v-btn>
            </div>
            <div v-if="metadata._id">
              <v-row class="">
                <v-col cols="6" class="">
                  <div class="px-2 border-on radius-on">
                    <div class="pa-2 font-weight-bold">Basic Information</div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-2 align-center hoverable">
                      <div class="mr-2">Comparative:</div>
                      <v-tooltip right content-class="tooltip-right" class="mr-2">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        <div class="text-center">This number indicates how many comparitives are available in the current project.<br/> Eg: "2" means you have Amount 1 Vs Amount 2 & Amount 1 Vs Amount 3 </div>
                      </v-tooltip>
                      <v-spacer></v-spacer>
                      <lb-dropdown :disabled="embed || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" width="120px" hidedetails label="" :items="comparativeList" v-model="comparative"/>
                    </div>
                    <div class="d-flex pa-2 align-center hoverable">
                      <div class="mr-2">Amount 1 Column Name:</div>
                      <v-spacer></v-spacer>
                      <lb-string hidedetails width="250px" label="" :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" v-model="setting.amount1name" />
                    </div>
                    <div class="d-flex pa-2 align-center hoverable">
                      <div class="mr-2">Amount 2 Column Name:</div>
                      <v-spacer></v-spacer>
                      <lb-string hidedetails width="250px" :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="" v-model="setting.amount2name" />
                    </div>
                    <div class="d-flex pa-2 align-center hoverable" v-if="comparative > 1">
                      <div class="mr-2">Amount 3 Column Name:</div>
                      <v-spacer></v-spacer>
                      <lb-string hidedetails width="250px" :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="" v-model="setting.amount3name" />
                    </div>
                    <div class="d-flex pa-2 align-center hoverable" v-if="comparative > 2">
                      <div class="mr-2">Amount 4 Column Name:</div>
                      <v-spacer></v-spacer>
                      <lb-string hidedetails width="250px" :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="" v-model="setting.amount4name" />
                    </div>
                    <div class="d-flex pa-2 align-center hoverable" v-if="comparative > 3">
                      <div class="mr-2">Amount 5 Column Name:</div>
                      <v-spacer></v-spacer>
                      <lb-string hidedetails width="250px" :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="" v-model="setting.amount5name" />
                    </div>
                    <div class="caption px-2 mt-4 pb-2">
                      <div class="mr-1 font-weight-bold">Information:</div>
                      <div>
                        This information is collected to display the headings on the Analysis page. Since you can use any data set for comparison you can define a custom name for eache column.<br/>
                        If you are comparing 2 amounts relating to current month, previous month and current month budget. The above settings would be as follows:<br/>
                        Comparitives: 1<br/>
                        Amount 1 column name: Current Month<br/>
                        Amount 2 column name: Previous Month<br/>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="">
                  <div class="px-2 border-on radius-on">
                    <div class="pa-2 font-weight-bold">Materiality Information</div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-2 align-center hoverable">
                      <div class="mr-2">{{ setting.amount1name || "Amount 1" }} Vs {{ setting.amount2name || "Amount 2" }}:</div>
                      <v-spacer></v-spacer>
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" class="mr-2" prependiconinner="mdi-cash" label="Amount" width="170px" hidedetails v-model="setting.materialityamount1" min="0" />
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="Percentage" prependiconinner="mdi-percent" step="0.01" width="150px" hidedetails v-model="setting.materialitypercent1" min="0" />
                    </div>
                    <div class="d-flex pa-2 align-center hoverable" v-if="comparative > 1">
                      <div class="mr-2">{{ setting.amount1name || "Amount 1" }} Vs {{ setting.amount3name || "Amount 3" }}:</div>
                      <v-spacer></v-spacer>
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" class="mr-2" prependiconinner="mdi-cash" label="Amount" width="170px" hidedetails v-model="setting.materialityamount2" min="0" />
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="Percentage" prependiconinner="mdi-percent" step="0.01" width="150px" hidedetails v-model="setting.materialitypercent2" min="0" />
                    </div>
                    <div class="d-flex pa-2 align-center hoverable" v-if="comparative > 2">
                      <div class="mr-2">{{ setting.amount1name || "Amount 1" }} Vs {{ setting.amount4name || "Amount 4" }}:</div>
                      <v-spacer></v-spacer>
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" class="mr-2" prependiconinner="mdi-cash" label="Amount" width="170px" hidedetails v-model="setting.materialityamount3" min="0" />
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="Percentage" prependiconinner="mdi-percent" step="0.01" width="150px" hidedetails v-model="setting.materialitypercent3" min="0" />
                    </div>
                    <div class="d-flex pa-2 align-center hoverable" v-if="comparative > 3">
                      <div class="mr-2">{{ setting.amount1name || "Amount 1" }} Vs {{ setting.amount5name || "Amount 5" }}:</div>
                      <v-spacer></v-spacer>
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" class="mr-2" prependiconinner="mdi-cash" label="Amount" width="170px" hidedetails v-model="setting.materialityamount4" min="0" />
                      <lb-number :disabled="(!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" label="Percentage" prependiconinner="mdi-percent" step="0.01" width="150px" hidedetails v-model="setting.materialitypercent4" min="0" />
                    </div>
                    <div class="caption px-2 mt-4 pb-2">
                      <div class="mr-1 font-weight-bold">Information:</div>
                      <div>
                        In this section you can define the materiality for triggering the workflow for receiving the comments against the identified variance.<br/>
                        If the variance cross either the specified amount or the percentace, the GL will be flagged for requiring comment:<br/>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="!metadata._id" class="grey--text title text-center pa-8">
              No Configuration found
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      right:"fluxmanagement_project",
      pid: "",
      comparativeList: [1, 2, 3, 4],
      metadata: {},
      setting: {},
      comparative: 1,
      loading: false,
    };
  },
  props: {
    project: {
      type: String,
      default: ""
    },
    embed: {
      type: Boolean,
      default: false
    },
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.pid = this.project || this.$route.params.id;
      this.loading = true;
      this.setting = {};
      this.axios
        .post("/v2/fluxmanagement/projectsetting/getdetail/" + this.pid)
        .then((ele) => {
          if (ele.data.status === "success") {
            let data = ele.data.data[0] || {};
            this.metadata = data.metadata || {};
            this.setting = data.setting || {};
            delete this.setting._id;
            this.comparative = this.setting.comparative || 1;
          }else throw Error("Error loading configuration");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addConfig() {
      //console.log(this.setting);
      this.loading = true;
      this.axios
        .post("/v2/fluxmanagement/projectsetting/add/" + this.pid,
          { data: this.setting }
        )
        .then((ele) => {
          if (ele.data.status === "success") {
            this.setting = {};
            this.refreshData();
            this.$store.commit("sbSuccess", "Added successfully");
          } else {
            throw new Error("Error Add Config");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateConfig() {
      //console.log(this.setting);
      this.loading = true;
      this.axios
        .post("/v2/fluxmanagement/projectsetting/edit/" + this.pid,
          { data: this.setting }
        )
        .then((ele) => {
          if (ele.data.status === "success") {
            this.setting = {};
            this.refreshData();
            this.$store.commit("sbSuccess", "updated successfully");
          } else {
            throw new Error("Error update Config");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    comparative(d) {
      this.setting.comparative = d
    }
  }
};
</script>

<style></style>